import { React, useEffect, useState } from "react";
import HeroSlider from "../Slider/HeroSlider";
import { Link as ScrollLink } from "react-scroll";
import TextTransition, { presets } from "react-text-transition";

const Hero12 = ({ data }) => {
  const sliderImages = data.sliderImages;
  const title = data.title;
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      <div className="st-height-b45 st-height-lg-b45" id="home"></div>
      <div className="st-hero-wrap overflow-hidden st-gradient" id="home">
        <div className="st-wave-animation st-white" />
        <div className="st-hero st-style1">
          <div className="container">
            <div className="st-hero-text st-white">
              <div className="st-height-b40 st-height-lg-b40" />
              <h1 className="st-hero-title cd-headline slide">
                <br />
                Pharmaceutical for
                <span className="cd-words-wrapper">
                  <TextTransition springConfig={presets.wobbly}>
                    <span
                      className="st-transition-title"
                      style={{ color: "#000" }}
                    >
                      {title[index % title.length]}
                    </span>
                  </TextTransition>
                </span>
              </h1>
              <div className="st-hero-subtitle">
                Diagcon is your trusted partner in pharmaceuticals and medical
                equipment, <br /> providing high-quality solutions to meet
                diverse healthcare needs.
              </div>
              <div className="st-hero-btn-group">
                <a
                  href="https://wa.me/+18607062669?text=Hey! I'm looking for medical inspection."
                  target="_blank"
                  className="st-btn st-style1 st-color5 st-smooth-move"
                >
                  Consultation
                </a>
                <a
                  href="https://shop.diagcon.co"
                  target="_blank"
                  className="st-btn st-style2 st-color2 st-smooth-move"
                >
                  Shop Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <HeroSlider data={sliderImages} />
      </div>
    </>
  );
};

export default Hero12;
